import React from 'react';
import { Badge } from 'react-bootstrap';
import _ from 'lodash';

const StatusBadge = (props) => {
  let bg = "secondary";
  let text = _.get(props, 'text', '');
  if (props.text === "ACTIVE") {
    bg = "primary";
  } else if (props.text === "CONNECTED") {
    bg = "success";
  }
  return <Badge bg={bg}>{props.text}</Badge>
};

const Icon = (props) => {
  const name = _.get(props, 'name', '');
  const className = _.get(props, 'class', '');
  if(!_.isEmpty(className)) {
    return <span className={`oi oi-${name} ${className}`} title={name} aria-hidden="true"></span>;
  } else {
    return <span className={`oi oi-${name}`} title={name} aria-hidden="true"></span>;
  }
};

export {
    StatusBadge,
    Icon
}