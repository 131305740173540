import React from "react";
import { SocketProvider } from "../status/SocketProvider.jsx";
import { JoystickControl } from "./JoystickControl.jsx";
import { Badge, CloseButton } from "react-bootstrap";
import useWindowFocus from "use-window-focus";
import useLocalStorage from "react-use-localstorage";

const EmbeddedJoystickControl = () => {
    const isWindowFocused = useWindowFocus();
    const [selectedTarget, setSelectedTarget] = useLocalStorage("selectedJoystickTarget", "*")
    const [isJoystickConnected, setIsJoystickConnected] = React.useState(false);
    const [showControlBox, setShowControlBox] = React.useState(false);
    var controlBoxVisibility = showControlBox ? "visible" : "hidden";

    const TargetBadge = (props) => {
        let isTargetSelected = props.target !== '*' && props.target !== '';
        if(isTargetSelected) {
            return <Badge className="top-right-badge"
                          onClick={()=>setShowControlBox(true)}
                          bg={isWindowFocused && isJoystickConnected ? 'success' : 'secondary'}>{props.target}</Badge>
        } else if (isJoystickConnected) {
            return <Badge className="top-right-badge"
                          onClick={()=>setShowControlBox(true)}
                          bg="secondary">SELECT TARGET</Badge>
        } else {
            return null;
        }
    }
    return (
        <SocketProvider namespace="/joystick">
            <TargetBadge target={selectedTarget.toUpperCase()} />
            <div className="joystick-controlbox" style={{visibility: controlBoxVisibility}}>
                <CloseButton className="joystick-controlbox-close" onClick={()=>setShowControlBox(false)} />
                <JoystickControl showGamepadStatus={false}
                                 onJoystickConnectionChange={setIsJoystickConnected}
                                 onTargetSelected={setSelectedTarget} />
            </div>
        </SocketProvider>
    );
}

export {
    EmbeddedJoystickControl
}
