import React from 'react';
import { Form, Alert, ProgressBar, Table, Container, Row, Col } from 'react-bootstrap';
import { Icon } from '../status/Widgets.jsx';
import { ShepardJoystick } from './ShepardJoystick.jsx';
import { GamepadSvg } from './GamepadSvg.jsx';
import axisMap from './AxisMap.json';
import useLocalStorage from 'react-use-localstorage';

const GamepadStatus = (props) => {
  const gamepad = props.gamepad;
  if(!gamepad) {
    return null;
  }

  const displayAxis = (axes, axisData) => {
    let value = axes[axisData.axis];
    if(axisData.reversed) {
      value = -value;
    }
    //console.log(`Axis value for ${axisData.axis}:`, value); // Add this line
    return (
      <ProgressBar className="no-transition-progressbar" now={value} min={-1} max={1} variant="success" />
    );
  }

  const displayButton = (pressed, held) => {
    const doUseChrisIcons = false;
    if (doUseChrisIcons) {
      if(held) {
        return (<Icon name="timer" title="Held" />);
      } else if (pressed) {
        return (<Icon className="chargeicon-blue" name="x" title="Pressed" />);
      } else {
        return (<Icon className="chargeicon-grey" name="x" title="Not pressed" />);
      }
    }
    else {
      if(held) {
        return <span role="img" aria-label="held">⏰</span>;
      } else if (pressed) {
        return <span role="img" aria-label="pressed">✅</span>;
      } else {
        return <span role="img" aria-label="not pressed">❌</span>;
      }
    }
  }

  const displayButtonIndex = (buttonIndex) => {
    //console.log('All gamepad buttons:', gamepad.buttons); // Log the state of all buttons
    const held = false; // TODO: where does this come from?
    // Check if the button at the provided index exists
    if (typeof gamepad.buttons[buttonIndex] === 'undefined') {
      console.warn(`Warning: Button at index ${buttonIndex} does not exist.`);
      return displayButton(false, false);
    }
    try {
      const pressed = gamepad.buttons[buttonIndex]?.pressed ?? false;
      //console.log(`Button at index ${buttonIndex} pressed:`, pressed, 'Button held:', held);
      return displayButton(pressed, held);
    } catch (error) {
      console.error(`Error in displayButton for button index ${buttonIndex}:`, error);
      return displayButton(false, false);
    }
  }

  const isEstopPressed = (buttons) => {
    return axisMap.estop.buttons.map(buttonid => {
      return buttons[buttonid].pressed;
    }).reduce((result, pressed) => {
      return result && pressed;
    });
  }

  const svgDisplay = () => {
    return (<GamepadSvg buttons={gamepad.buttons} axes={gamepad.axes} />);
  }
  
  return (
    <Container fluid className="mt-3">
    <Row>
      <Col>
        <Table borderless={true} className="mt-0">
          <tbody>
            <tr>
              <td style={{width: "5%"}}><strong>Roll</strong></td>
              <td>{displayAxis(gamepad.axes, axisMap.roll)}</td>
            </tr>
            <tr>
              <td><strong>Pitch</strong></td>
              <td>{displayAxis(gamepad.axes, axisMap.pitch)}</td>
            </tr>
            <tr>
              <td><strong>Throttle</strong></td>
              <td>{displayAxis(gamepad.axes, axisMap.throttle)}</td>
            </tr>
            <tr>
              <td><strong>Yaw</strong></td>
              <td>{displayAxis(gamepad.axes, axisMap.yaw)}</td>
            </tr>
          </tbody>
        </Table>
        <Table borderless={true} className="mt-1" size="sm">
          <tbody>
            <tr>
              <td style={{width: "10%"}}>
                <strong>Takeoff</strong>
              </td>
              <td style={{width: "10%"}}>
                {displayButtonIndex(axisMap.takeoff.button)}
              </td>
              <td style={{width: "10%"}}>
                <strong>Manual</strong>
              </td>
              <td style={{width: "10%"}}>
                {displayButtonIndex(axisMap.manual.button)}
              </td>
              <td style={{width: "10%"}}>
                <strong>Land</strong>
              </td>
              <td style={{width: "10%"}}>
                {displayButtonIndex(axisMap.land.button)}
              </td>
              <td style={{width: "10%"}}>
                <strong>Return</strong>
              </td>
              <td style={{width: "10%"}}>
                {displayButtonIndex(axisMap.rtl.button)}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                (Start Button)
              </td>
              <td colSpan={2}>
                (South Button)
              </td>
              <td colSpan={2}>
                (Back Button)
              </td>
              <td colSpan={2}>
                (North Button)
              </td>
            </tr>
            <tr>
              <td style={{width: "10%"}}>
                <strong>ESTOP</strong>
              </td>
              <td style={{width: "10%"}}>
                {displayButton(isEstopPressed(gamepad.buttons), false)}
              </td>
              <td style={{width: "10%"}}>
                <strong>Gimbal Up</strong>
              </td>
              <td style={{width: "10%"}}>
                {displayButtonIndex(axisMap.gimbalUp.button)}
              </td>
              <td style={{width: "10%"}}>
                <strong>Gimbal Down</strong>
              </td>
              <td style={{width: "10%"}}>
                {displayButtonIndex(axisMap.gimbalDn.button)}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                (East + West)
              </td>
              <td colSpan={2}>
                (D-Pad Up)
              </td>
              <td colSpan={2}>
                (D-Pad Down)
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col sm={5} className="text-center">
        {svgDisplay()}
      </Col>
    </Row>
    </Container>
  );
}

const GamepadSelector = (props) => {
  const gamepads = props.gamepads;
  const onSelect = (event) => {
    props.onSelect(event.target.value);
  }
  const gamepadOptions = Object.keys(gamepads).map(gamepadId => {
    return (
      <option value={gamepads[gamepadId].index} key={gamepads[gamepadId].index}>{gamepads[gamepadId].id}</option>
    );
  });
  return (
    <div>
    <h4>Select Gamepad</h4>
    <Form.Select aria-label="Select Gamepad" onChange={onSelect} value={props.selected}>
      {gamepadOptions}
    </Form.Select>
    </div>
  );
}

const NoGamepadsAvailableAlert = () => {
  return (
    <div>
      <Alert variant="light">
      <Alert.Heading>No Gamepads Detected</Alert.Heading>
      <p>
        Connect a gamepad and then press any button on it to begin.
      </p>
      </Alert>
    </div>
  );
}

const JoystickControl = (props) => {

  const [gamepads, setGamepads] = React.useState({});
  const requestRef = React.useRef();
  const [selectedGamepadId, setSelectedGamepadId] = useLocalStorage('selectedGamepad', 0);
  const [gamepadConnected, setGamepadConnected] = React.useState(false);

  React.useEffect(() => {
    if(props.onJoystickConnectionChange) {
      props.onJoystickConnectionChange(gamepadConnected);
    }
  }, [gamepadConnected])

  const animate = () => {
    const detectedGamepads = [...navigator.getGamepads()];
    const gamepadsUpdated = detectedGamepads.reduce((acc, gamepad) => {
      if (gamepad) {
        acc[gamepad.index] = gamepad;
      }
      return acc;
    }, {});
    setGamepads(prevGamepads => ({
      ...prevGamepads,
      ...gamepadsUpdated
    }));
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  });

  const gamepadIds = Object.keys(gamepads);
  const numGamepads = gamepadIds.length;
  if(gamepads && numGamepads > 0) {
    if(!gamepadConnected) {
      setGamepadConnected(true);
    }
    if(!gamepadIds.includes(selectedGamepadId)) {
      setSelectedGamepadId(gamepadIds[0]);
    }
    if(gamepads[selectedGamepadId]) {
      //console.log('Rendering GamepadStatus with gamepad:', gamepads[selectedGamepadId]);
      const selectedGamepad = gamepads[selectedGamepadId];
      return (
          <>
            <GamepadSelector gamepads={gamepads} selected={selectedGamepadId} onSelect={setSelectedGamepadId} />
            {props.showGamepadStatus ? <GamepadStatus gamepad={selectedGamepad} /> : null}
            <ShepardJoystick gamepad={selectedGamepad} onTargetSelected={props.onTargetSelected}/>
          </>
      );
    } else {
      return (
          <GamepadSelector gamepads={gamepads} onSelect={setSelectedGamepadId} />
      );
    }
  } else {
    if(gamepadConnected) {
      setGamepadConnected(false);
    }
    return (
        <NoGamepadsAvailableAlert />
    );
  }
}

export {
  JoystickControl
}