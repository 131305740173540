import React from 'react';
import { useSocket } from './SocketProvider.jsx'
import { Badge, Button, Table } from 'react-bootstrap';
import { Icon } from './Widgets.jsx';
import { Howl, Howler } from 'howler';
import _ from 'lodash'

const NotificationPriorityBadge = (props) => {
    let bg = "secondary";
    switch(props.text) {
        case "FATAL":
            bg = "danger";
            break;
        case "ERROR":
            bg = "danger";
            break;
        case "WARN":
            bg = "warning";
            break;
        case "INFO":
            bg = "info";
            break;
        case "DEBUG":
            bg = "secondary";
            break;
        case "TRACE":
            bg = "light";
            break;
    }
    return <Badge bg={bg}>{props.text}</Badge>;
  }
  
  const NotificationButton = (props) => {
    const socket = useSocket();
    let variant;
    if(props.button.isPressed) {
      variant = "secondary";
    } else{
      variant = "primary";
    }
    let buttonData = {button_id: props.button.id};

    let notificationId;
    try {
      notificationId = _.get(props, 'notification.id', null); // Safe access to notification.id
    } catch (error) {
      console.error("Failed to access notification id:", error);
      notificationId = null; // Set a fallback if an error occurs
    }

    if(notificationId) {
      buttonData = {button_id: props.button.id, notification_id: notificationId};
    }
    const onButtonPress = () => {
      console.log(`onButtonPress: ${buttonData.notification_id}:${buttonData.button_id}`);
      socket.emit("button", buttonData);
    }
    return <Button variant={variant} 
                   disabled={props.button.isPressed}
                   onClick={onButtonPress}>{props.button.label}</Button>;
  }
  
  const NotificationRow = (props) => {
    const buttonList = props.buttons.map(b => 
      <li key={b.button.id} className="list-inline-item">
        <NotificationButton button={b.button} notification={b.notification} />
      </li>
    );

    // set up sound for an audible notification
    const sound = React.useRef(null);
    const playCount = React.useRef(0);
    const playSound = () => {
      sound.current.play();
      playCount.current = playCount.current + 1;
      if(playCount.current < props.audible.playbackCount) {
        setTimeout(playSound, props.audible.playbackPeriodInS * 1000);
      }
    };
    const setupSound = () => {
      if(props.audible && props.audible.playbackFilename !== '') {
        sound.current = new Howl({
          src: [props.audible.playbackFilename]
        });
        sound.current.once('load', () => {
          playSound();
        });
      }
    };
    React.useEffect(setupSound, []);

    return (
      <tr>
      <td>
        <ul className="list-unstyled list-inline mb-0">
          <li key="badge" className="list-inline-item"><NotificationPriorityBadge text={props.priority} /></li>
          <li key="text" className="list-inline-item">{props.text}</li>
        </ul>
      </td>
      <td>
        <ul className="list-unstyled list-inline mb-0">{buttonList}</ul>
      </td>
    </tr>
    );
  }
  
  const NotificationTable = () => {
    const socket = useSocket();
    const [notifications, setNotifications] = React.useState([]);
  
    const timerId = React.useRef(0);
    React.useEffect(() => {
      socket.on("usernotification", (e) => {
        // update state
        setNotifications(e);
  
        // clear notifications after 3 seconds if data stops coming in
        clearTimeout(timerId.current);
        timerId.current = setTimeout(() => {
          setNotifications([]);
        }, 3000);
      });
    }, []);
  
    var rows = [];
    if(notifications) {
      if(notifications && notifications instanceof Array && notifications.length > 0) {
        // count takeoffs and advances 
        let takeoffsWaiting = 0;
        let advancesWaiting = 0;
        for (var i in notifications) {
            let n = notifications[i];
            for(let j in n.button) {
                let b = n.button[j];
                if(b.id === 'takeoffNowBtn') {
                    takeoffsWaiting += 1;
                } else if (b.id === 'advanceMissionBtn') {
                    advancesWaiting += 1;
                }
            }
        }
        // show buttons to takeoff / advance groups
        if(takeoffsWaiting > 1) {
          const buttonProperties = {
            button: {
              id: "takeoffAllNowButton", 
              infoText: "Takeoff ALL Now", 
              label: "Takeoff ALL Now", 
              isPressed: false },
            notification: null
          };
          rows.push(
            <NotificationRow 
              key="takeoffAllNowButton"
              priority="INFO"
              buttons={[buttonProperties]}
              text={`There are ${takeoffsWaiting} takeoffs waiting for user release.`} />
          );
        }
        if(advancesWaiting > 1) {
          const buttonProperties = {
            button: {
              id: "advanceAllNowButton", 
              infoText: "Advance ALL Missions Now", 
              label: "Advance ALL Missions Now", 
              isPressed: false },
            notification: null
          };
          rows.push(
            <NotificationRow 
              key="advanceAllNowButton"
              priority="INFO"
              buttons={[buttonProperties]}
              text={`There are ${advancesWaiting} missions waiting for user advancement.`} />
          );
        }
        // all other button types
        for(let i in notifications) {
          let n = notifications[i];
          let audible = null;
          if(n.audible) {
            audible = n.audible;
          }
          let buttons;
          if(n.button && n.button instanceof Array && n.button.length > 0) {
            buttons = n.button.map(b => {
              return {button: b, notification: n};
            });
          } else {
            buttons = [];
          }
          rows.push(
            <NotificationRow
              key={n.id}
              priority={n.priority}
              buttons={buttons}
              audible={audible}
              text={n.message}/>
          );
        }
      }
    }
    if(rows.length === 0) {
      // no rows to display, hide the table.
      return null;
    }
    return (
      <div id="usernotification_table">
          <h4>User Notifications</h4>
          <Table>
              <thead>
              <tr>
                  <th>Message</th>
                  <th>Actions</th>
              </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
          </Table>
      </div>
    );
  }

export {
  NotificationTable
};
