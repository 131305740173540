import React from 'react';
import { createRoot } from 'react-dom/client';

import { NotificationTable } from './NotificationTable.jsx'
import { ServiceTable } from './ServiceTable.jsx'
import { VehicleTable } from './VehicleTable.jsx'
import { SocketProvider } from './SocketProvider.jsx'
import { EmbeddedJoystickControl } from "../joystick/EmbeddedJoystickControl.jsx";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <SocketProvider namespace="/shepard">
      <NotificationTable />
      <ServiceTable />
      <VehicleTable />
    </SocketProvider>
    <EmbeddedJoystickControl />
  </React.StrictMode>
);