import React from 'react';
import { useSocket } from './SocketProvider.jsx';
import { StatusBadge } from './Widgets.jsx';
import { Table } from 'react-bootstrap';

const ServiceTableRow = (props) => {
  const lastUpdate = props.status.lastUpdate;
  return (
    <tr>
      <td><StatusBadge text={props.status.state} /></td>
      <td>{props.name}</td>
      <td>{lastUpdate}</td>
    </tr>
  );
};

const ServiceTable = () => {
  const socket = useSocket();
  const [serviceStatus, setServiceStatus] = React.useState({
    lastTelemTime: 0,
    lastNotifyTime: 0,
    timeoutCount: 0,
  });

  // subscribe to sockets and record last received times
  React.useEffect(() => {
    socket.on("telem", (e) => {
      setServiceStatus((last) => {
        return { ...last, lastTelemTime: Date.now() };
      });
    });
    socket.on("usernotification", (e) => {
      setServiceStatus((last) => {
        return { ...last, lastNotifyTime: Date.now() };
      });
    });
  }, []);

  // render once per second even if data doesn't come in
  const timerId = React.useRef(0);
  React.useEffect(() => {
    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => {
      setServiceStatus((last) => {
        return { ...last, timeoutCount: last.timeoutCount + 1 };
      });
    }, 1000);
  });

  const genStatus = (lastTime) => {
    if (lastTime === 0) {
      return { state: "UNKNOWN", lastUpdate: "Never" };
    } else {
      const now = Date.now();
      const secElapsed = Math.floor((now - lastTime) / 1000);
      const timeString = new Date(lastTime).toLocaleTimeString("en-US", { hour12: false });
      const lastUpdateString = `${timeString} (${secElapsed}s ago)`;
      if (secElapsed > 3) {
        return { state: "DISCONNECTED", lastUpdate: lastUpdateString };
      } else {
        return { state: "CONNECTED", lastUpdate: lastUpdateString };
      }
    }
  };
  return (
    <div id="service_table">
      <h4>Service Status</h4>
      <Table>
        <thead className="thead-light">
          <tr>
            <th>Status</th>
            <th>Service</th>
            <th>Last Update</th>
          </tr>
        </thead>
        <tbody>
          <ServiceTableRow name="SHEPARD Telemetry" status={genStatus(serviceStatus.lastTelemTime)} />
          <ServiceTableRow name="SHEPARD AppLayer" status={genStatus(serviceStatus.lastNotifyTime)} />
        </tbody>
      </Table>
    </div>
  );
};

export {
    ServiceTable
};