import React from 'react';
import { Form, Table,  Badge } from 'react-bootstrap';
import { useSocket } from '../status/SocketProvider.jsx'
import axisMap from './AxisMap.json';
import useLocalStorage from "react-use-localstorage";
import useWindowFocus from "use-window-focus";
import _ from 'lodash';

const ShepardJoystick = (props) => {
  const keepaliveInterval = 500;
  const gamepad = props.gamepad;
  const socket = useSocket();
  const windowFocused = useWindowFocus();
  const [status, setStatus] = React.useState([]);
  const [isConnected, setIsConnected] = React.useState(false);
  const [selectedVehicle, setSelectedVehicle] = useLocalStorage("selectedJoystickTarget", "*");
  const axisData = React.useRef({
    "axis_rll": 0,
    "axis_pit": 0,
    "axis_thr": 0,
    "axis_yaw": 0
  });
  const gimbalCmd = React.useRef({
    "gimbalPitchSpdPct": 0,
    "gimbalZoomSpdPct": 0
  })
  const buttonsPressed = React.useRef({
    "takeoff": false,
    "land": false,
    "manual": false,
    "rtl": false,
    "estop": false,
    "gimbalUp": false,
    "gimbalDn": false
  });

  const telemTimer = React.useRef(0);
  const stickSendTimer = React.useRef(0);

  // receive telemetry
  React.useEffect(() => {
    socket.on("joystickTelem", (e) => {
      // update state
      setStatus(e);
      setIsConnected(true);
    
      // clear status after 3 seconds if data stops coming in
      clearTimeout(telemTimer.current);
      telemTimer.current = setTimeout(() => {
        setStatus([]);
        setIsConnected(false);
      }, 3000);
    });
  }, []);

  const sendKeepalive = (selected, focused) => {
    if(selected !== '*' && selected !== '' && focused) {
      //console.log(`Sending keepalive. Selected: ${selected}, Focused: ${focused}`);
      let event = {
        "targetId": selected,
        "type": 0,
      }
      socket.emit("joystickEvent", event);
    }
  }

  // set up keepalives whenever a target is selected or window focus changes
  React.useEffect(() => {
    console.log('useEffect: [selectedVehicle, windowFocused] triggered');
    clearInterval(stickSendTimer.current);
    if(selectedVehicle !== "*") {
      stickSendTimer.current = setInterval(sendKeepalive, keepaliveInterval, selectedVehicle, windowFocused);
    }
    if(props.onTargetSelected) {
      props.onTargetSelected(selectedVehicle);
    }
  }, [selectedVehicle, windowFocused]);

  const processAxis = (gamepad, axisData) => {
    // Ensure gamepad is defined
    if (!gamepad) return 0;

    const gamepadAxisValue = gamepad.axes[axisData.axis];
    const isReversed = axisData.reversed;
    let pctAxisValue = Math.round(gamepadAxisValue * 100);
    if(isReversed) {
      pctAxisValue = -pctAxisValue;
    }
    return pctAxisValue;
  }

  const isEstopPressed = (buttons) => {
    return axisMap.estop.buttons.map(buttonid => {
      return buttons[buttonid].pressed;
    }).reduce((result, pressed) => {
      return result && pressed;
    });
  }

  const isButtonPressed = (gamepad, buttonIndex) => {
    // Ensure gamepad is defined
    if (!gamepad) return false;

    // Check if the button at the provided index exists
    if (typeof gamepad.buttons[buttonIndex] === 'undefined') {
      console.warn(`Warning: Button at index ${buttonIndex} does not exist.`);
      return false; // Default to false if the button doesn't exist
    }
    try {
      // Safely access the 'pressed' property, defaulting to false if undefined
      return gamepad.buttons[buttonIndex]?.pressed ?? false;
    } catch (error) {
      console.error(`Error checking pressed state for button index ${buttonIndex}:`, error);
      return false; // Default to false in case of an error
    }
  }

  // send joystick events if we should
  React.useEffect(() => {
    //console.log(`Gamepad available: ${!!gamepad}, Window focused: ${windowFocused}, Selected vehicle: ${selectedVehicle}`);
    if(gamepad && windowFocused && selectedVehicle !== '' && selectedVehicle !== "*") {
      // check changes in sticks
      let sticksChanged = false;
      let newRollValue = processAxis(gamepad, axisMap.roll);
      let newPitchValue = processAxis(gamepad, axisMap.pitch);
      let newThrottleValue = processAxis(gamepad, axisMap.throttle);
      let newYawValue = processAxis(gamepad, axisMap.yaw);
      if(newRollValue !== axisData.current.axis_rll) {
        axisData.current.axis_rll = newRollValue;
        sticksChanged = true;
      }
      if(newPitchValue !== axisData.current.axis_pit) {
        axisData.current.axis_pit = newPitchValue;
        sticksChanged = true;
      }
      if(newThrottleValue !== axisData.current.axis_thr) {
        axisData.current.axis_thr = newThrottleValue;
        sticksChanged = true;
      }
      if(newYawValue !== axisData.current.axis_yaw) {
        axisData.current.axis_yaw = newYawValue;
        sticksChanged = true;
      }

      // check changes in buttons
      // either estop is held, or one of the buttons is released
      let buttonEvent = 0;
      if(!buttonsPressed.current.estop && isEstopPressed(gamepad.buttons)) {
        console.log('Sending ESTOP event.');
        buttonEvent = 0xde;
      } else if (buttonsPressed.current.takeoff && !isButtonPressed(gamepad, axisMap.takeoff.button)) {
        console.log('Sending Takeoff event.');
        buttonEvent = 2;
      } else if (buttonsPressed.current.land && !isButtonPressed(gamepad, axisMap.land.button)) {
        console.log('Sending Land event.');
        buttonEvent = 3;
      } else if (buttonsPressed.current.rtl && !isButtonPressed(gamepad, axisMap.rtl.button)) {
        console.log('Sending RTL event.');
        buttonEvent = 4;
      } else if (buttonsPressed.current.manual && !isButtonPressed(gamepad, axisMap.manual.button)) {
        console.log('Sending Manual Control event.');
        buttonEvent = 7;
      }
      
      // check gimbal commands
      buttonsPressed.current.gimbalUp = isButtonPressed(gamepad, axisMap.gimbalUp.button);
      buttonsPressed.current.gimbalDn = isButtonPressed(gamepad, axisMap.gimbalDn.button);
      let gimbalCmdChanged = false;
      if (buttonsPressed.current.gimbalDn && gimbalCmd.current.gimbalPitchSpdPct !== -15) {
        console.log("down");
        gimbalCmd.current.gimbalPitchSpdPct = -15;
        gimbalCmdChanged = true;
      } else if (buttonsPressed.current.gimbalUp && gimbalCmd.current.gimbalPitchSpdPct !== 15) {
        gimbalCmd.current.gimbalPitchSpdPct = 15;
        gimbalCmdChanged = true;
      } else if (!buttonsPressed.current.gimbalDn && !buttonsPressed.current.gimbalUp && gimbalCmd.current.gimbalPitchSpdPct !== 0) {
        gimbalCmd.current.gimbalPitchSpdPct = 0;
        gimbalCmdChanged = true;
      }

      buttonsPressed.current.estop = isEstopPressed(gamepad.buttons);
      buttonsPressed.current.takeoff = isButtonPressed(gamepad, axisMap.takeoff.button);
      buttonsPressed.current.land = isButtonPressed(gamepad, axisMap.land.button);
      buttonsPressed.current.rtl = isButtonPressed(gamepad, axisMap.rtl.button);
      buttonsPressed.current.manual = isButtonPressed(gamepad, axisMap.manual.button);

      if(buttonEvent !== 0) {
        let event = {
          "targetId": selectedVehicle,
          "type": buttonEvent,
        }
        //console.log("sending button event");
        socket.emit("joystickEvent", event);
      }

      if(sticksChanged) {
        let event = {
          "targetId": selectedVehicle,
          "type": 1,
          "axisData": axisData.current
        }
        //console.log("sending joystick event");
        socket.emit("joystickEvent", event);
      }

      if(gimbalCmdChanged) {
        let event = {
          "targetId": selectedVehicle,
          "type": 8,
          "gimbalCommand": gimbalCmd.current
        }
        socket.emit("joystickEvent", event);
      }

    }
  }, [gamepad.timestamp]);

  let targetList = null;
  let numTargets = 0;
  let vehicleMap = {};
  if(_.isArray(status) && status.length > 0) {
    numTargets = status.length;
    targetList = _.sortBy(status, ['id']).map(vehicle => {
      vehicleMap[vehicle.id] = vehicle;
      return (
        <option value={vehicle.id} key={vehicle.id}>{vehicle.id}</option>
      );
    });
  }

  // reset selected vehicle if it ceases to exist
  if(numTargets > 0 && selectedVehicle !== '*' && !(selectedVehicle in vehicleMap)) {
    setSelectedVehicle('*');
  }

  let statusBadge = (
    <Badge bg="secondary">DISCONNECTED</Badge>
  );
  if(isConnected && windowFocused) {
    statusBadge = (
      <Badge bg="success">CONNECTED</Badge>
    );
  } else if (isConnected && !windowFocused) {
    statusBadge = (
      <Badge bg="warning">NOT FOCUSED</Badge>
    );
  }

  const TelemData = () => {
    if(selectedVehicle in vehicleMap) {
      return (
        <Table className="mt-2">
          <thead>
            <tr>
              <th>Altitude Above Takeoff (m)</th>
              <th>Heading (deg)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{vehicleMap[selectedVehicle].relAlt.toFixed(2)}</td>
              <td>{vehicleMap[selectedVehicle].heading.toFixed(0)}</td>
              <td>{vehicleMap[selectedVehicle].mode}, {vehicleMap[selectedVehicle].isActive ? "ACTIVE": "IDLE"}</td>
            </tr>
          </tbody>
        </Table>
          
      );
    } else {
      return null;
    }
  }

  return (
    <>
    <h4 className="mt-3">Select Target</h4>
    <p>{statusBadge} There are {numTargets} targets available in SHEPARD</p>
    <Form.Select aria-label="Select Target" value={selectedVehicle} onChange={(event) => setSelectedVehicle(event.target.value)}>
      <option value="-" key="-">-- None Selected --</option>
      {targetList}
    </Form.Select>
    <TelemData />
    </>
  );
}

export {
  ShepardJoystick
}