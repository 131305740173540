import React from 'react';
import _ from 'lodash';
import { io } from "socket.io-client";

const SocketContext = React.createContext();

const SocketProvider = (props) => {
    const namespace = _.get(props, 'namespace', '/');
    const socket = React.useRef(io(namespace));
    socket.current.on("connect_error", (err) => {
        console.log("Could not connect: ", err.message); 
    });
    return <SocketContext.Provider value={socket.current}>{props.children}</SocketContext.Provider>;
} 

const useSocket = () => {
    const context = React.useContext(SocketContext);
    if(_.isUndefined(context)) {
        throw new Error("useSocket must be used within a SocketProvider");
    }
    return context;
}

export {
    SocketProvider,
    useSocket
};