import React from "react";
import { useSocket } from "./SocketProvider.jsx";
import { StatusBadge, Icon } from "./Widgets.jsx";
import { ProgressBar, Table, Dropdown, Modal, Button } from "react-bootstrap";
import _ from 'lodash';

const MissionProgress = (props) => {
  const progress = Math.round(props.progress * 100);
  return <ProgressBar variant="info" now={progress} label={`${progress}%`} />;
};

const AltitudeDisplay = (props) => {
  let pose = _.get(props, 'vehicle.pose');
  if (!_.isUndefined(pose) && _.get(props, 'vehicle.isArmed')) {
    let alt = _.get(pose, 'position.altitude');
    let altInM = alt.toFixed(1);
    let altInFt = (altInM * 3.28084).toFixed(1);
    return (
      <div>
        <span className="altitudeicon">
          <Icon name="arrow-thick-top" title="Altitude" />
          &nbsp;
        </span>
        <span className="altitudetext">
          {altInM}m ({altInFt}ft)
        </span>
      </div>
    );
  } else {
    return <div />;
  }
};

const FuelDisplay = (props) => {
  let fuelRemaining = _.get(props, 'vehicle.fuelRemaining');
  if(_.isUndefined(fuelRemaining)) {
    fuelRemaining = 0;
  }
  const fuelLevel = Math.round(fuelRemaining * 100);
  let fuelClass = "info";
  if (fuelLevel < 20) {
    fuelClass = "danger";
  } else if (fuelLevel < 50) {
    fuelClass = "warning";
  }
  return <ProgressBar variant={fuelClass} now={fuelLevel} label={`${fuelLevel}%`} />;
};

const PrecisionIcon = (props) => {
  const hAcc = _.get(props, 'vehicle.hAccuracyInM');
  if(_.isUndefined(hAcc)) {
    return "";
  } else if (hAcc < 1.0) {
    return <Icon name="target" title="High Precision" class="text-primary" />
  } else if (hAcc < 2.0) {
    return <Icon name="target" title="High Precision" class="text-secondary" />;
  } else {
    return "";
  }
};

const ConfirmDisarmModal = (props) => {
  const sock = useSocket();
  const vid = props.vid;
  const [response, setResponse] = React.useState("");
  const onButton = () => {
    sock.emit("vehicleAction", {
      action: "EMERGENCY_STOP_MOTORS",
      vehicleId: vid,
      confirm: true
    }, (ackData) => {
      console.log(ackData);
      if(ackData.success) {
        setResponse(<span className="text-success mx-3">Success!</span>);
      } else {
        setResponse(<span className="text-danger mx-3">Failed!</span>);
      }
    });
    setTimeout(() => {
      setResponse("");
    }, 5000);
  };

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Force Disarm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This action will <strong>immediately</strong> disarm vehicle <strong>{vid}</strong> and stop its motors!</p>
        <p>This can cause complete loss of control of the vehicle.</p>
      </Modal.Body>
      <Modal.Footer>
        {response}<Button onClick={onButton} variant="danger">Force Disarm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ConfirmFloorModal = (props) => {
  const sock = useSocket();
  const vid = props.vid;
  const [response, setResponse] = React.useState("");
  const onButton = () => {
    sock.emit("vehicleAction", {
      action: "GOTO_AIRSPACE_FLOOR",
      vehicleId: vid,
    }, (ackData) => {
      console.log(ackData);
      if(ackData.success) {
        setResponse(<span className="text-success mx-3">Success!</span>);
      } else {
        setResponse(<span className="text-danger mx-3">Failed!</span>);
      }
    });
    setTimeout(() => {
      setResponse("");
    }, 5000);
  };
  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Send To Airspace Floor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This action will <strong>immediately</strong> send vehicle <strong>{vid}</strong> to the airspace floor.</p>
        <p>This emergency maneuver can result in crashing into terrain!</p>
      </Modal.Body>
      <Modal.Footer>
        {response}<Button onClick={onButton} variant="danger">Send To Airspace Floor</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ActionsDropdown = (props) => {
  const sock = useSocket();
  const vid = _.get(props, 'vehicle.id', '');
  const onButton = (actionId) => {
    sock.emit("vehicleAction", {
      action: actionId,
      vehicleId: vid
    });
  }
  const [showConfirmDisarmDialog, setShowConfirmDisarmDialog] = React.useState(false);
  const [showConfirmFloorDialog, setShowConfirmFloorDialog] = React.useState(false);
  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" size="sm">
        <Icon name="cog" title="Vehicle Actions" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>Direct Command</Dropdown.Header>
        <Dropdown.Item onClick={()=>onButton("RTL")}>Return to Launch</Dropdown.Item>
        <Dropdown.Item onClick={()=>onButton("LAND")}>Land Immediately</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Shepard Command</Dropdown.Header>
        <Dropdown.Item onClick={()=>onButton("GOTO_LZ")}>Go to LZ</Dropdown.Item>
        <Dropdown.Item onClick={()=>onButton("SHEPARD_CONTROL")}>Shepard Control</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Emergency</Dropdown.Header>
        <Dropdown.Item onClick={()=>setShowConfirmFloorDialog(true)}>Go to Airspace Floor</Dropdown.Item>
        <Dropdown.Item onClick={()=>setShowConfirmDisarmDialog(true)}>Force Disarm</Dropdown.Item>
      </Dropdown.Menu>
      <ConfirmDisarmModal show={showConfirmDisarmDialog} vid={vid} onHide={()=>setShowConfirmDisarmDialog(false)}></ConfirmDisarmModal>
      <ConfirmFloorModal show={showConfirmFloorDialog} vid={vid} onHide={()=>setShowConfirmFloorDialog(false)}></ConfirmFloorModal>
    </Dropdown>
  );
};

const VehicleTableRow = (props) => {
  const vehicle = _.get(props, 'vehicle');
  if(_.isUndefined(vehicle)) {
    return null;
  }
  const isArmed = _.get(vehicle, 'isArmed', false);
  const isLinkAlive = _.get(vehicle, 'isLinkAlive', false);
  const vid = _.get(vehicle, 'id', '');
  const state = _.get(vehicle, 'state', 'UNKNOWN_STATE');
  const missionProgress = _.get(vehicle, 'progress', 1);
  const uasPlatformMessage = _.get(vehicle, 'uasPlatformMessage', '');
  const missionid = _.get(vehicle, 'missionid', '');

  let badgeText;
  if (isArmed) {
    badgeText = "ACTIVE";
  } else {
    if (isLinkAlive) {
      badgeText = "CONNECTED";
    } else {
      badgeText = "NO LINK";
    }
  }
  return (
    <tr>
      <td>
        <ul className="list-unstyled mb-0">
          <li key="vehicleId">{vid}</li>
          <li key="vehicleStatusBadge">
            <StatusBadge text={badgeText} />
            &nbsp;
            <PrecisionIcon vehicle={vehicle}/>
          </li>
        </ul>
      </td>
      <td>
        <ul className="list-unstyled mb-0">
          <li key="vehicleState">{state}</li>
          <li key="vehicleMissionProgress">
            <MissionProgress progress={missionProgress} />
          </li>
          <li key="vehiclePlatformMessage" className="small">
            {uasPlatformMessage}
          </li>
        </ul>
      </td>
      <td>
        <div>{missionid}</div>
        <AltitudeDisplay vehicle={vehicle} />
      </td>
      <td>
        <FuelDisplay vehicle={vehicle} />
      </td>
      <td>
        <ActionsDropdown vehicle={vehicle} />
      </td>
    </tr>
  );
};

const VehicleTable = () => {
  const socket = useSocket();
  const [telem, setTelem] = React.useState(null);
  const timerId = React.useRef(0);

  React.useEffect(() => {
    socket.on("telem", (e) => {
      // update state
      setTelem(e);

      // clear data after 3 seconds if it stops coming in
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        setTelem(null);
      }, 3000);
    });
  }, []);

  var rows = [];
  var vehicles = _.get(telem, 'vehicle');
  if(_.isArray(vehicles) && !_.isEmpty(vehicles)) {
    _.sortBy(telem.vehicle, ['id']).forEach((vehicle) => {
      rows.push(<VehicleTableRow key={vehicle.id} vehicle={vehicle} />);
    });
  }
  if (rows.length === 0) {
    // no rows to display, hide the table
    return null;
  }

  return (
    <div id="vehicle_table">
      <h4>Vehicle Status</h4>
      <Table>
        <thead>
          <tr>
            <th style={{width: "15%"}}>ID</th>
            <th style={{width: "50%"}}>State</th>
            <th style={{width: "15%"}}>Mission</th>
            <th style={{width: "20%"}}>Fuel</th>
            <th style={{width: "0%"}}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};

export { VehicleTable };
